import React from 'react'
import { FirebaseServiceProvider } from 'theme/services/firebase-service'
import { TimezoneProvider } from 'theme/services/timezoneService'
import { AuthProvider } from 'theme/services/auth/auth-service'
import { GlobalServiceProvider } from 'theme/services/global-service'

const MainLayout = ({ children }) => {
  return (
    <GlobalServiceProvider>
      <FirebaseServiceProvider>
        <AuthProvider disabled>
          <TimezoneProvider>{children}</TimezoneProvider>
        </AuthProvider>
      </FirebaseServiceProvider>
    </GlobalServiceProvider>
  )
}

export default MainLayout
